import { Observable } from 'rxjs';
import { Operations } from 'state-domains/domain/subscription';
import { buildConfigurationCategoriesOperationUrl } from 'state-domains/network';

import { CATEGORY_TYPES, ConfigurationCategory } from '../types';
import { createOperationObservable } from '../utils';

export const modifyConfigurationCategory = (
    operation: Operations,
    categoryType: CATEGORY_TYPES,
    payload: any = {},
    id = '',
): Observable<{ data: ConfigurationCategory }> => {
    const modifiedType =
        categoryType === CATEGORY_TYPES.DISPATCH_HEADER ? CATEGORY_TYPES.HEADER : categoryType;
    const dataFn = (item: ConfigurationCategory) => ({ ...item, categoryType });
    return createOperationObservable<ConfigurationCategory>(
        operation,
        (id = '') => buildConfigurationCategoriesOperationUrl(modifiedType, id),
        dataFn,
        id,
        payload,
    );
};
